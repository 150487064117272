import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { MCDuplicateMessage } from "@/lib/constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function fetchCSRFToken(): string {
  const csrfElement: HTMLElement | null = document?.querySelector(
    'meta[name="csrf-token"]',
  );
  const csrfToken =
    csrfElement instanceof HTMLMetaElement ? csrfElement.content : "";
  return csrfToken;
}

export function getClientUrl(): string {
  let host = "";

  if (typeof window !== "undefined") {
    host = window.location.host;
  }

  // TODO: find a better way to support local dev
  if (host.includes("localhost") || host.includes("127.0.0.1")) {
    return `http://${host}`;
  }

  return `https://${host}`;
}

export function trackEvent(event_name: string, context: object = {}): void {
  window.dataLayer.push({ event: event_name, ...context });
}

export function santizeHTTPError(error: object): {
  message: string;
  code: string;
} {
  if (error.code.includes("MC0007")) {
    return { message: MCDuplicateMessage, code: error.code };
  }

  return error;
}
