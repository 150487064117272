import "./instrumentation";
import "./config";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "@/app";

document.addEventListener("turbo:load", () => {
  let root = document.querySelector("#root");
  if (root === null) {
    console.log("[ERR] Cannot find the root element");
    root = document.body;
  }
  const ReactRoot = createRoot(root);
  ReactRoot.render(<App />);
});
