import ky from "ky";
import { fetchCSRFToken, getClientUrl } from "@/lib/utils";

interface QueryParameters {
  [index: string]: string;
}

export class ApiService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  apiClient: any;
  baseUrl: string;

  constructor(apiClient = ky, baseUrl = getClientUrl()) {
    this.apiClient = apiClient;
    this.baseUrl = baseUrl;
  }

  get<T>(
    path: string | URL,
    queryParameters: QueryParameters = {},
    options: object = {},
  ) {
    return ky
      .get(this.prepareUrl(path, queryParameters), { ...options })
      .json() as T;
  }

  post(path: string | URL, body: object = {}, options: object = {}) {
    const csrfToken = fetchCSRFToken();
    return ky
      .extend({
        hooks: {
          beforeRequest: [
            (request) => {
              request.headers.set("Content-Type", "application/json");
              request.headers.set("X-CSRF-Token", csrfToken);
            },
          ],
        },
      })
      .post(this.prepareUrl(path), { json: body, ...options })
      .json();
  }

  private prepareUrl(
    path: string | URL,
    queryParameters: QueryParameters = {},
  ): string {
    const url: URL = new URL(path, this.baseUrl);
    let key: keyof QueryParameters;
    for (key in queryParameters) {
      url.searchParams.set(key, queryParameters[key]);
    }
    return url.href;
  }
}
