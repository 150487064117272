import { ReactNode } from "react";
import { Check, ChevronDown } from "lucide-react";
import { FieldError } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FormControl } from "@/components/ui/form";
import React from "react";

interface DataProps {
  label: string | ReactNode;
  triggerLabel?: string | ReactNode; // Use this to label the selected item on the combobox.
  searchValue: string;
  value: string;
}

interface ComboboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  data: Array<DataProps>;
  error?: FieldError;
  setValue(value: string): void;
  contentClassName?: string;
  triggerPlaceholder?: string | ReactNode;
  searchInputPlaceholder?: string;
  emptyPlaceholder?: string;
  disabled: boolean;
}

export const Combobox = React.forwardRef<HTMLButtonElement, ComboboxProps>(
  ({
    data,
    error,
    value,
    setValue,
    contentClassName,
    triggerPlaceholder,
    searchInputPlaceholder,
    emptyPlaceholder,
    disabled,
  }, ref) => {
    const selectedData = data.find((data) => data.value === value);
    return (
      <Popover>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              disabled={disabled}
              error={error}
              variant="combobox"
              size="lg"
              role="combobox"
              className={cn(
                "w-full justify-between px-3",
                !value && "text-muted-foreground",
              )}
              ref={ref}
            >
              {selectedData
                ? selectedData.triggerLabel || selectedData.label
                : triggerPlaceholder}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 text-primary" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className={contentClassName}>
          <Command>
            <CommandInput placeholder={searchInputPlaceholder}  />
            <CommandEmpty>
              {emptyPlaceholder || "No matching results."}
            </CommandEmpty>
            <CommandGroup>
              {data.map((data) => (
                <CommandItem
                  key={data.value}
                  onSelect={() => {
                    setValue(data.value);
                  }}
                >
                  <Check
                    className={cn(
                      "mx-2 h-4 w-4",
                      data.value === value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {data.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
Combobox.displayName = 'Combobox';
