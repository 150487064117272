import { zodResolver } from "@hookform/resolvers/zod";
import { HTTPError } from "ky";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Send } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { AscendaButton } from "@/components/ascenda-button";
import { Combobox } from "@/components/combobox";
import { CountryPhoneInput } from "@/components/country-phone-input";
import { FormContainder } from "@/components/form-container";
import { EmailsCard } from "@/components/emails-card";
import { ErrorDialog } from "@/components/error-dialog";
import { ErrorSign } from "@/components/error-sign";
import { SuccessPage } from "@/components/success-page";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelOptional,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import ascenda_logo from "@/images/ascenda_logo.svg";
import placeholder_flag from "@/images/placeholder_flag.svg";
import { ApiService } from "@/lib/api-service";
import { GenericEmailDomain, Countries } from "@/lib/constants";
import { BootstrapData } from "@/lib/types";
import { trackEvent, santizeHTTPError as sanitizeHTTPError } from "@/lib/utils";

const apiService = new ApiService();

// The E.164-compliant regexp is \d{1,14} which allows special shortcodes, etc.
// We only want regular "consumer" numbers, so let's expect at least 8 numbers (including IDD phone code).
// Ref: https://www.twilio.com/docs/glossary/what-e164
const E164_REGEXP = /^\+[1-9]\d{8,14}$/;
const MESSAGE_MAX_LENGTH = 1000;

const FormSchema = z.object({
  tenant_name: z
    .string({ required_error: "Please enter your company's name" })
    .min(1, { message: "Please enter your company's name" }),
  country_code: z
    .string({ required_error: "Please enter your country" })
    .min(1, { message: "Please enter your country" }),
  first_name: z
    .string({ required_error: "Please enter your first name" })
    .min(1, { message: "Please enter your first name" }),
  last_name: z
    .string({ required_error: "Please enter your last name" })
    .min(1, { message: "Please enter your last name" }),
  job_title: z.string().optional(),
  email: z
    .string({ required_error: "Please enter your email address" })
    .min(1, { message: "Please enter your email address" })
    .email({ message: "Please enter a valid email address" })
    .refine(
      (data) => {
        const emailDomain = data.split("@")[1];
        return !GenericEmailDomain.includes(emailDomain);
      },
      {
        message: "Please use a company email address",
      },
    ),
  phone_number: z
    .string()
    .refine((data) => isValidPhoneNumber(data), {
      message: "Please enter a valid phone number",
    })
    .optional(),
  message: z
    .string()
    .max(MESSAGE_MAX_LENGTH, {
      message: "Please keep your message within the set character limit",
    })
    .optional(),
});

export function ContactPage() {
  const navigate = useNavigate();
  const [showEmailList, setShowEmailList] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenErrorDialog] = useState(false);
  const form = useForm<z.infer<typeof FormSchema>>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(FormSchema),
  });
  const { mutateAsync: submitContactForm } = useMutation({
    mutationFn: (data: z.infer<typeof FormSchema>) => {
      return apiService.post("/api/contact", data, { timeout: 10_000 });
    },
  });

  const {
    isLoading: isBootstrapping,
    error: _,
    data: bootstrapData,
  } = useQuery({
    queryKey: ["tenant-bootstrap"],
    queryFn: async () => {
      return await apiService.get<BootstrapData>("/api/tenants/bootstrap");
    },
  });

  useEffect(() => {
    if (!isBootstrapping) {
      form.setValue("email", bootstrapData?.email || "");
      form.setValue("tenant_name", bootstrapData?.tenant_name || "");
    }
  }, [bootstrapData, form.setValue]);

  async function onSubmit(formData: z.infer<typeof FormSchema>) {
    setLoading(true);

    trackEvent("contact_form_submit", {
      tenant_name: formData.tenant_name,
      company_domain: formData.email.split("@")[1],
    });

    try {
      await submitContactForm(formData);
      setServerError("");
      setLoading(false);
      navigate("/contact/success");
    } catch (error) {
      if (error instanceof HTTPError && error.response.status != 500) {
        const errorJson = await error.response.json();
        const errorObj = errorJson.errors[0];
        const sanitizedError = sanitizeHTTPError(errorObj);
        setServerError(sanitizedError.message);
      } else {
        setOpenErrorDialog(true);
      }

      setLoading(false);
      Sentry.captureException(error);
    }
  }

  return (
    <FormContainder>
      <div className="m-auto flex flex-col justify-center max-lg:w-10/12 w-1/2">
        <ErrorSign
          className="my-8"
          hidden={serverError === ""}
          error_message={serverError}
        />
        <div className="w-full hidden max-lg:block mb-14 mt-4">
          <img src={ascenda_logo} className="w-32" alt="" />
        </div>
        <div className="text-destructive pb-2 text-sm">CONTACT SALES</div>

        <div className="text-4xl font-bold text-primary pb-6 pr-6">
          Want to learn more about our product?
        </div>
        <div className="text-primary pb-8">
          {`We're always happy to answer questions and get you acquainted with Ascenda, from recommending use cases for your company to discussing pricing options.`}
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-2 w-full"
          >
            <div className="text-primary">
              <div className="font-bold pb-2">Your details</div>
              <div className="pb-6">
                To get started, simply leave your contact details and one of our
                sales representatives will be in touch soon.
              </div>
            </div>

            <FormField
              control={form.control}
              name="tenant_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>COMPANY NAME</FormLabel>
                  <FormControl>
                    <Input
                      disabled={loading && isBootstrapping}
                      type="text"
                      placeholder="Central Perk Inc."
                      error={form.formState.errors.tenant_name}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country_code"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>COUNTRY</FormLabel>
                  <Combobox
                    data={Countries.map((country) => {
                      return {
                        label: (
                          <div className="flex space-x-2 items-center">
                            <span className="text-3xl">{country.flag}</span>
                            <span>{country.name}</span>
                          </div>
                        ),
                        searchValue: country.name,
                        value: country.iso2,
                      };
                    })}
                    disabled={loading}
                    contentClassName="w-full max-sm:w-80 lg:w-80"
                    setValue={(value) => {
                      form.setValue(field.name, value, { shouldValidate: true });
                    }}
                    triggerPlaceholder={
                      <div className="flex space-x-2">
                        <img src={placeholder_flag} alt="" />
                        <span>Select</span>
                      </div>
                    }
                    searchInputPlaceholder="Search"
                    error={form.formState.errors.country_code}
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>FIRST NAME</FormLabel>
                    <FormControl>
                      <Input
                        disabled={loading}
                        type="text"
                        placeholder="Ross"
                        error={form.formState.errors.first_name}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>LAST NAME</FormLabel>
                    <FormControl>
                      <Input
                        disabled={loading}
                        type="text"
                        placeholder="Geller"
                        error={form.formState.errors.last_name}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="job_title"
              render={({ field }) => (
                <FormItem>
                  <FormLabelOptional>JOB TITLE</FormLabelOptional>
                  <FormControl>
                    <Input
                      disabled={loading}
                      type="text"
                      placeholder="Head of Loyalty"
                      error={form.formState.errors.job_title}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>YOUR COMPANY EMAIL ADDRESS</FormLabel>
                  <FormControl>
                    <Input
                      data-sentry-mask
                      disabled={loading && isBootstrapping}
                      type="text"
                      placeholder="ross.geller@centralperk.com"
                      error={form.formState.errors.email}
                      onFocus={() => {
                        setShowEmailList(true);
                      }}
                      {...field}
                      {...form.register("email", {
                        onBlur: () => {
                          setShowEmailList(false);
                        },
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                  <EmailsCard show={showEmailList} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabelOptional>PHONE NUMBER</FormLabelOptional>
                  <FormControl>
                    <CountryPhoneInput
                      setValue={(value) => form.setValue(field.name, value)}
                      onBlur={() => form.trigger("phone_number")}
                      value={field.value}
                      disabled={loading}
                      error={form.formState.errors.phone_number}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <div className="flex space-x-2 items-center text-lg font-bold">
                    <div className="text-primary">Your message</div>
                    <div className="text-muted">(optional)</div>
                  </div>
                  <div className="text-primary pb-4">
                    Providing us with your questions in advance will help us
                    prepare for when we connect.
                  </div>
                  <FormLabel className="hidden">MESSAGE</FormLabel>
                  <FormControl>
                    <Textarea
                      disabled={loading}
                      placeholder="Add your message here"
                      error={form.formState.errors.message}
                      maxChars={MESSAGE_MAX_LENGTH}
                      className="text-primary"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <AscendaButton
              content="Send enquiry"
              loading={loading}
              rightIcon={
                <div className="ml-2">
                  <Send className="h-4 w-4" />
                </div>
              }
            />
          </form>
        </Form>
      </div>
      <ErrorDialog
        title="Oh no, something went wrong..."
        content="An unexpected error has occured, sorry about that. We've been notified and will get started on a fix."
        open={openDialog}
        onOpenChange={setOpenErrorDialog}
      />
    </FormContainder>
  );
}

export function ContactPageSuccess() {
  return (
    <SuccessPage
      heading={`Thank you for inquiry!`}
      message={`One of our sales representatives will soon be in touch with you.`}
    />
  );
}
