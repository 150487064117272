import * as React from "react";
import { FieldError } from "react-hook-form";
import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxChars?: number;
  error?: FieldError;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, value, error, maxChars, ...props }, ref) => {
    let classNames =
      "flex min-h-[250px] h-fit w-full rounded-md border-input px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50";
    if (error) {
      classNames += "border-destructive";
    } else {
      classNames += "border";
    }

    const charCount = value?.toString().length || 0;
    const charMax = maxChars || 1000;
    return (
      <div>
        <textarea className={cn(classNames, className)} ref={ref} {...props} />
        <div
          className={`flex flex-row text-sm ${error ? "text-destructive" : "text-muted-foreground"}`}
        >
          <div className="grow">{error?.message || ""}</div>
          <div>
            {charCount}/{charMax}
          </div>
        </div>
      </div>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
