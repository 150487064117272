import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface ErrorDialogProps {
  title: string;
  content: string;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  title,
  content,
  open,
  onOpenChange,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="sm:max-w-md w-96"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <div className="flex flex-col space-y-8 pl-2">
          <DialogHeader>
            <DialogTitle className="pr-6">{title}</DialogTitle>
          </DialogHeader>
          <div className="flex items-center space-x-2 pr-6">
            <div className="grid flex-1 gap-2 text-primary font-light">
              {content}
            </div>
          </div>
        </div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button type="button">Try again</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
