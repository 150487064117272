import React from "react";
import { AppRouter } from "./routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";

const queryClient = new QueryClient();

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {AppRouter}
      <Toaster />
    </QueryClientProvider>
  );
}
