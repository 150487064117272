import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  enabled: ["staging", "production"].includes(import.meta.env.VITE_RAILS_ENV),
  environment: import.meta.env.VITE_RAILS_ENV,
  dsn: "https://a9375a590fd674f6d05a9fa20a212fa9@o4507248894541824.ingest.us.sentry.io/4507309389316096",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia:
        import.meta.env.VITE_RAILS_ENV === "production" ? true : false,
      networkDetailAllowUrls:
        import.meta.env.VITE_RAILS_ENV === "production"
          ? undefined
          : [window.location.host],
    }),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.browserProfilingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 0.05,
  debug: false,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});
