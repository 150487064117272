import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

interface AscendaButtonProps {
  content: string;
  rightIcon?: React.ReactNode;
  loading: boolean;
}

export const AscendaButton: React.FC<AscendaButtonProps> = ({
  rightIcon,
  content,
  loading,
}) => {
  return (
    <Button
      size="lg"
      type="submit"
      className="flex max-sm:space-x-[60%] max-lg:w-full disable"
      disabled={loading}
    >
      <div>{content}</div>
      {!loading && rightIcon}
      {loading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
    </Button>
  );
};
