import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ContactPage,
  ContactPageSuccess,
} from "@/features/contact-page/contact-page";
import { Demo } from "@/features/demo/demo";
import {
  TenantCreation,
  TenantCreationSuccess,
} from "@/features/tenant-creation/tenant-creation";

const AppRouter = (
  <Router>
    <Routes>
      <Route path="/" element={<Demo />} />
      <Route path="/sign-up" element={<TenantCreation />} />
      <Route path="/sign-up/success" element={<TenantCreationSuccess />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/contact/success" element={<ContactPageSuccess />} />
    </Routes>
  </Router>
);

export { AppRouter };
