import { getCountries } from "libphonenumber-js";
import * as React from "react";
import { useState } from "react";
import PhoneInput, { type Country } from "react-phone-number-input/input";

import { Input } from "@/components/ui/input";
import { Combobox } from "@/components/combobox";
import placeholder_flag from "@/images/placeholder_flag.svg";
import { Countries } from "@/lib/constants";
import { FieldError } from "react-hook-form";

interface CountryPhoneInputProps {
  setValue: (e164Number: string) => void;
  onBlur: () => void;
  value?: string;
  disabled: boolean;
  error?: FieldError;
}

const libCountries = new Set(getCountries().map((iso2) => iso2 as string));
const PHONE_COUNTRIES = Countries.filter((country) =>
  libCountries.has(country.iso2),
);

export const CountryPhoneInput: React.FC<CountryPhoneInputProps> = ({
  setValue,
  onBlur,
  value,
  disabled,
  error,
}) => {
  const [country, setCountry] = useState("");

  const onCountryChange = (value: string) => {
    setCountry(value);
    setValue("");
  };

  return (
    <div className="flex flex-row">
      <div className="w-min-20">
        <Combobox
          data={PHONE_COUNTRIES.map((country) => {
            return {
              label: (
                <div className="flex space-x-2 items-center">
                  <span className="text-3xl">{country.flag}</span>
                  {/* <span>{country.phone_code}</span> */}
                  <span>{country.name}</span>
                </div>
              ),
              triggerLabel: (
                <div className="flex space-x-2 items-center">
                  <span className="text-3xl">{country.flag}</span>
                  {/* <span>{country.phone_code}</span> */}
                </div>
              ),
              searchValue: country.name,
              value: country.iso2,
            };
          })}
          triggerPlaceholder={
            <div className="flex space-x-2">
              <img src={placeholder_flag} alt="" />
              <span></span>
            </div>
          }
          searchInputPlaceholder="Search"
          contentClassName="w-full max-sm:w-20 lg:w-full"
          disabled={disabled}
          setValue={onCountryChange}
          value={country}
          error={error}
        />
      </div>

      <PhoneInput
        international
        withCountryCallingCode
        country={country.toUpperCase() as Country}
        value={value}
        inputComponent={Input}
        onChange={(value) => {
          setValue(value as string);
        }}
        onBlur={onBlur}
        placeholder="+1 234 567 890"
        className={error ? "border-destructive" : null}
      />
    </div>
  );
};
