import { FormContainder } from "@/components/form-container";
import ascenda_logo from "@/images/ascenda_logo.svg";

interface SuccessPageProps {
  heading: string;
  message: string;
}

export function SuccessPage({ heading, message }: SuccessPageProps) {
  return (
    <FormContainder>
      <div className="mx-auto max-lg:mt-0 mt-40 flex flex-col justify-center max-lg:w-10/12 w-1/2">
        <div className="w-full hidden max-lg:block mb-16 mt-4">
          <img src={ascenda_logo} className="w-32" alt="" />
        </div>
        <div className="text-destructive pb-2 text-sm">ALL DONE</div>
        <div className=" max-md:text-2xl text-4xl font-bold text-primary pb-6">
          {heading}
        </div>
        <div className="text-primary pb-6">{message}</div>
      </div>
    </FormContainder>
  );
}
