import { Card } from "@/components/ui/card";
import green_checkmark from "@/images/green_checkmark.svg";
import red_crossmark from "@/images/red_crossmark.svg";

interface EmailsCardProps {
  show: boolean;
}

export const EmailsCard: React.FC<EmailsCardProps> = ({ show }) => {
  return (
    <Card className={show ? "" : "hidden"}>
      <div className="flex max-lg:flex-col lg:space-x-8">
        <div className="space-y-2 pt-2 pb-2">
          <div>
            <img src={green_checkmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@abcbank.com</span>
          </div>
          <div>
            <img src={green_checkmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@boostbank.com</span>
          </div>
          <div>
            <img src={green_checkmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@assetavenue.com</span>
          </div>
        </div>
        <div className="space-y-2 pt-2 pb-2">
          <div>
            <img src={red_crossmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@gmail.com</span>
          </div>
          <div>
            <img src={red_crossmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@outlook.com</span>
          </div>
          <div>
            <img src={red_crossmark} className="inline mr-2 ml-2" alt="" />
            <span className="text-sm">name@yahoo.com</span>
          </div>
        </div>
      </div>
    </Card>
  );
};
