import error_cross from "@/images/error_cross.svg";
import { cn } from "@/lib/utils";

interface ErrorSignProps {
  error_message: TrustedHTML;
  hidden: boolean;
  className?: string;
}

export const ErrorSign: React.FC<ErrorSignProps> = ({
  error_message,
  hidden,
  className,
}) => {
  const baseClasses = `flex bg-error text-card-foreground py-4 pl-4 pr-8 space-x-4 rounded ${hidden ? "hidden" : ""}`;
  return (
    <div className={cn(baseClasses, className)}>
      <img src={error_cross} alt="" className="h-6 w-6" />
      <div
        className="text-justify text-sm"
        dangerouslySetInnerHTML={{ __html: error_message }}
      ></div>
    </div>
  );
};
